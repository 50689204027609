@import '../../styles/abstract/variables';

.container {
  min-height: calc(100vh);
  background: $color-background;
  flex-grow: 1;
}

.contentDiv {
  position: absolute;
  background: $color-background;
}

.appBarDiv {
  position: fixed;
  z-index: 3;
}
