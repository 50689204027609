@import '../../styles/abstract/variables';
@import '../../styles/abstract/mixins';

.sideBar {
  background-color: $color-primary-dark-blue;
  height: 100%;
  padding: 0;
  z-index: 4;
  position: fixed;
  left: 0;
  top: 0;
  flex-grow: 1;
}

.logoWrapper {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 19px;
  img {
    width: 181px;
  }
}

.hiddenLogoWrapper {
  align-items: center;
  height: 80px;
  margin-left: 20px;
  margin-top: 20px;

  img {
    width: 40px;
  }
}

.container {
  background: $color-primary-dark-blue;
  display: flex;
  align-items: center;
  height: 80px;
}

.languageText {
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 16px;
  color: white;
}

p {
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 16px;
}

.innerWrapper {
  padding-top: 22px;
  padding-bottom: 22px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.profileWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  margin-left: 12px;
  font-size: 16px;
  font-family: 'SuisseIntl-Medium', sans-serif;
  color: white;
}

.image {
  display: block;
  width: 40px;
  height: 40px;
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  padding: 20px 20px 20px 0px;
  color: white;
  opacity: 0.9;
  transition: all 0.3s ease;
  font-weight: 500;
  height: 46px;
}

.linkActive {
  background: $side-bar-active-link;
  font-weight: 600;

  .sideBarItem {
    margin-left: 14px;
    .labelStyle {
      color: $project-color-white;
      font-family: SuisseIntl-Regular, sans-serif;
    }
    .iconStyle {
      color: $project-color-white;
    }
  }

  .yellowBorder {
    height: 46px;
    background: $color-primary-yellow;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 3px solid $color-primary-yellow;
    margin-right: 6px;
  }
}

.arrowIcon {
  height: 26px;
  margin-left: 15px;
}

.sideBarItem {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  margin-left: 20px;
  .labelStyle {
    font-family: SuisseIntl-Regular, sans-serif;
    margin-left: 12px;
    color: $side-bar-item-text-color;
    display: block;
  }
  .iconStyle {
    color: $side-bar-item-text-color;
  }
}

.listItem {
  height: 46px;
}

.list {
  margin-top: 20px;
}

.marginLeftClass {
  margin-left: 26px;
}
.marginRightClass0 {
  margin-right: 0 !important;
}

.menuIcon {
  cursor: pointer;
  color: $color-primary-yellow;
  width: 30px;
  height: 30px;
}

.hiddenMenuIcon {
  margin-left: 4px;
  margin-top: 8px;
}

.sideBarDataLinks {
  background: $side-bar-active-link;
}

.link {
  display: flex;
  color: #89a0a7;
  justify-content: space-between;
  align-items: center;
  padding: 27px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
}

.active {
  border-left: 4px solid #fff200;
  background: linear-gradient(
    270.79deg,
    rgba(255, 242, 0, 0) 0%,
    rgba(255, 242, 0, 0.2) 100%
  );
}

.dropDownLink {
  display: flex;
  color: #89a0a7;
  align-items: center;
  padding: 27px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
}

.sideBarItemDiv {
  display: flex;
}
