@import '../../styles/abstract/variables';

.container {
  background: $project-color-white;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 30px;
}

.languageText {
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 16px;
}

p {
  font-family: 'SuisseIntl-Regular', sans-serif;
  font-size: 16px;
}

.innerWrapper {
  background: $project-color-white;
  padding: 22px 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.profileWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  margin-left: 12px;
  font-size: 16px;
  font-family: 'SuisseIntl-Medium', sans-serif;
}

.image {
  display: block;
  width: 32px;
}

.root {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 350px;
  height: 40px;
  border: 1px solid #dedfe4;
  border-radius: 5px;
  background-color: #ffffff;
}

.inputField {
  flex: 1;
  margin-left: 10px;
}

.searchIcon {
  margin-left: 10px;
}

.hearLogo {
  width: 49px;
  margin-left: 33px;
}

.searchButton {
  background-color: #dedfe4;
  margin-left: 10px;
  color: #26214a;
  border-radius: 5px;
  border: none;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0 20px;
}

.clearSearchButton {
  background-color: white;
  margin-left: 10px;
  color: red;
  border-radius: 5px;
  border: none;
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0 20px;
}
